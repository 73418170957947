import { SnackbarOrigin } from "@mui/material";
import {
  ALERT_SEVERITY,
  IText,
  URGENCY,
} from "@paul/paul-components-collector-package";
import { IParams } from "./IParams";
import { TGenericData } from "./IPCD";
import { IEntityData } from "./IDynamicApiCall";
import { TCommand, TExecuteCommand } from "./ICommands";
import { IOT_COMMANDS } from "../constants";

export interface IRedirectProps extends IBaseCommandProps {
  url: string;
}

export interface ISendIotActionProps extends IAPIBaseCommandProps {
  targetEntities: string[];
  command: IOT_COMMANDS;
  parameters?: IParameter[];
}

export interface IShowSnackBarProps extends IBaseCommandProps {
  description: IText;
  title?: IText;
  severity?: ALERT_SEVERITY;
  variant?: "standard" | "filled" | "outlined";
  anchorOrigin?: SnackbarOrigin;
  urgency?: URGENCY;
}

export interface ISetDataMainProps {
  storageKey: string;
  storageData: TGenericData;
  params?: IParams;
}

export interface ISetDataProps extends ISetDataMainProps {
  commandCallback?: TCommand;
}

export interface ISetDataWithCallback extends ISetDataMainProps {
  eventDetails: TGenericData;
  commandCallback?: TExecuteCommand;
}

export interface IDownloadProps extends IBaseCommandProps {
  file: ISimpleFile | ISimpleFile[];
  downloadMode?: DOWNLOAD_MODE;
}

export interface ICreateEntityProps
  extends IPrepareMDMApiStructure,
    IAPIBaseCommandProps {
  relations?: Record<string, IPrepareCreateRelations>;
}

export interface IUpdateEntityProps
  extends IPrepareMDMApiStructure,
    ICreateEntityProps {
  id: string;
  relations?: Record<string, IPrepareUpdateRelations>;
}

export interface IPrepareMDMApiStructure {
  type: string;
  id?: string;
  relations?: Record<string, IPrepareRelations>;
  attributes?: Record<string, unknown>;
}

// Currently the only possible structure for a stable create/update for MDM entities
export interface IPrepareRelations {
  type: string;
  direction: string;
  relationIds: string[];
  previousEntity?: IEntityData;
}

export interface IPrepareCreateRelations
  extends Omit<IPrepareRelations, "previousEntity"> {}

export interface IPrepareUpdateRelations extends IPrepareRelations {
  previousEntity: IEntityData;
}

export interface ISimpleFile {
  filename: string;
  url: string;
  [key: string]: string;
}

interface IBaseCommandProps {
  params?: IParams;
  commandCallback?: TCommand;
}

interface IAPIBaseCommandProps extends IBaseCommandProps {
  upsertStorageKeyByResponse?: string;
  onSuccess?: TCommand;
  onError?: TCommand;
}

interface IParameter {
  name: string;
  value: string;
  valueType: string;
}

export enum DOWNLOAD_MODE {
  concurrent = "concurrent",
  sequence = "sequence",
}

import JSZip from "jszip";
import {
  DOWNLOAD_MODE,
  ICommandContext,
  ISimpleFile,
  TCommand,
  TGenericData,
  TPrepareCommand,
} from "../../interfaces";
import { isDownloadCommand } from "./guards";
import { getCommand } from "./getCommand";
import { createDynamicData } from "../createDynamicData";
import { getExtendedCommandContext } from "./getExtendedCommandContext";
import { getFileExtFromUrl } from "../getFileExtFromUrl";
import { addSentryLog } from "../../features/sentry/helpers/addSentryLog";
import { SENTRY_LOG_LEVEL } from "../../features/sentry/constants";

const ZIP_ERROR_TITLE = "Zip file Error";
const ZIP_ERROR_DESC = " Couldn't zip file correctly";

// Doc: https://actaqua.atlassian.net/wiki/x/AQD-j
export const download: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isDownloadCommand(command)) {
      console.warn(
        "Please check the download properties. Something seems to be wrong.",
      );

      return;
    }

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const {
      commandCallback,
      params,
      file,
      downloadMode = DOWNLOAD_MODE.concurrent,
    } = command.commandProps;

    const callbackCommandFunction = commandCallback
      ? getCommand(commandCallback, commandContext)
      : undefined;

    const initiateSingleDownload = (downloadFile: ISimpleFile) => {
      const fileExt = getFileExtFromUrl(downloadFile.url);
      const link = document.createElement("a");
      link.href = downloadFile.url;
      link.download = fileExt ? downloadFile.filename.concat(fileExt) : "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const getFormattedDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${year}${month}${day}-${hours}-${minutes}-${seconds}`;
    };

    const initiateZipDownload = async (files: ISimpleFile[]) => {
      const zip = new JSZip();
      const filenames: string[] = [];

      const processFilename = (name: string, iteration: number = 1): string => {
        if (filenames.includes(name)) {
          const newFilename = name.concat(`(${iteration})`);
          return processFilename(newFilename);
        }

        filenames.push(name);

        return name;
      };

      const getAndPrepareZipFile = async (downloadFile: ISimpleFile) => {
        try {
          const response = await fetch(downloadFile.url);
          const data = await response.blob();
          const fileExt = getFileExtFromUrl(downloadFile.url);
          const filename = processFilename(downloadFile.filename);

          zip.file(fileExt ? filename.concat(fileExt) : filename, data);

          return data;
        } catch (error) {
          addSentryLog({
            level: SENTRY_LOG_LEVEL.ERROR,
            title: ZIP_ERROR_TITLE,
            description: ZIP_ERROR_DESC,
            error,
          });

          return undefined;
        }
      };

      const zipFileContentAndDownload = async () => {
        const zipContent = await zip.generateAsync({ type: "blob" });
        const blob = new Blob([zipContent], { type: "application/zip" });
        const link = document.createElement("a");
        const dynamicFileName = `PAUL_Download_${getFormattedDateTime()}.zip`;
        link.href = URL.createObjectURL(blob);
        link.download = dynamicFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      if (downloadMode === DOWNLOAD_MODE.concurrent) {
        const remoteFiles = files.map((downloadFile) =>
          getAndPrepareZipFile(downloadFile),
        );

        await Promise.all(remoteFiles);
        zipFileContentAndDownload();

        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const downloadFile of files) {
        // eslint-disable-next-line no-await-in-loop
        await getAndPrepareZipFile(downloadFile);
      }

      zipFileContentAndDownload();
    };

    if (!params) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Array.isArray(file)
        ? initiateZipDownload(file)
        : initiateSingleDownload(file);
      callbackCommandFunction?.(eventDetails);
      return;
    }

    const dynamicallyGeneratedFile = createDynamicData(
      file as Record<string, unknown>,
      extendedCommandContext,
      params,
      eventDetails,
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Array.isArray(dynamicallyGeneratedFile)
      ? initiateZipDownload(dynamicallyGeneratedFile)
      : initiateSingleDownload(dynamicallyGeneratedFile as ISimpleFile);

    callbackCommandFunction?.(eventDetails);
  };

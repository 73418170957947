import { useEffect, useRef } from "react";
import { useAppDispatch } from "../../../hooks";
import { createDynamicData } from "../../../helpers/createDynamicData";
import { IDynamicApiCall } from "../../../interfaces";
import { executeAPICall } from "../../pcp/store/PCPSagaActions";
import { COMMANDS } from "../../../helpers/commands";
import { useContextData } from "../../../hooks/useContextData";
import { useCommandContext } from "../../../hooks/useCommandContext";

export const APIRequestDataComponent = ({
  storageKey,
  configuration,
  params,
  onSuccess,
  onError,
  initialData,
}: IDynamicApiCall) => {
  const isGettingExecuted = useRef(false);
  const dispatch = useAppDispatch();
  const contextData = useContextData();
  const commandContext = useCommandContext();
  const dynamicConfig = createDynamicData(
    configuration as Record<string, unknown>,
    contextData,
    params,
  );

  useEffect(() => {
    if (!isGettingExecuted.current && contextData?.userContext?.accessToken) {
      isGettingExecuted.current = true;

      dispatch(
        executeAPICall({
          storageKey,
          configuration: dynamicConfig,
          initialData,
          onSuccess: onSuccess
            ? COMMANDS[onSuccess.type](commandContext, onSuccess)
            : undefined,
          onError: onError
            ? COMMANDS[onError.type](commandContext, onError)
            : undefined,
        }),
      );
    }
  }, [storageKey, dynamicConfig, contextData?.userContext?.accessToken]);

  return null;
};

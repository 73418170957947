import i18n from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { DEFAULT_LANGUAGE } from "../constants";

const getLanguages = async () => {
  try {
    const supportedLngs = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/clients/languages`,
    );
    return await supportedLngs.json();
  } catch (error) {
    console.warn("Error while fetching languages", error);
    return [DEFAULT_LANGUAGE];
  }
};

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    fallbackLng: DEFAULT_LANGUAGE,
    ns: [process.env.REACT_APP_PCD_TRANSLATIONS_KEY || "translation"],
    partialBundledLanguages: true,
    debug: process.env.REACT_APP_TRANSLATIONS_DEBUG === "true",
    detection: {
      order: ["sessionStorage", "localStorage"],
    },
    load: "currentOnly",
    defaultNS: process.env.REACT_APP_PCD_TRANSLATIONS_KEY,
    backend: {
      loadPath: `${process.env.REACT_APP_PCD_TRANSLATIONS}/{{lng}}/{{ns}}`,
    },
    nsSeparator: false,
    interpolation: {
      formatSeparator: ",",
      format: (value, formatting) => {
        if (formatting && moment(value).isValid()) {
          return moment(value).format(formatting);
        }
        return value.toString();
      },
    },
  })
  .then(async () => {
    const supportedLngs = await getLanguages();
    i18n.languages = supportedLngs as string[];
  });

export default i18n;

import * as Sentry from "@sentry/react";
import { IErrorFactoryOptions } from "../interfaces/IErrorFactoryOptions";
import { SENTRY_LOG_LEVEL } from "../constants";

export function addSentryLog({
  level,
  description,
  title,
  error,
}: IErrorFactoryOptions): void {
  Sentry.withScope((scope) => {
    scope.setLevel(level);

    if (title) {
      scope.setTag("title", title);
    }

    scope.setExtra("description", description);

    if (level === SENTRY_LOG_LEVEL.ERROR) {
      scope.setExtra("originalError", error);
      Sentry.captureException(new Error(description));
    } else Sentry.captureMessage(description);
  });
}

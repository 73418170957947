import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ILoadingPCP } from "../interfaces/ILoadingPCP";
import { COMPONENT_TRANSLATIONS_NAMESPACE } from "../../../constants";

export const LoadingIndicator = ({ loadingDesc }: ILoadingPCP) => {
  const { t: tComponent } = useTranslation(COMPONENT_TRANSLATIONS_NAMESPACE);
  const LOADING_TEXT = "Loading...";

  return (
    <Box
      sx={{
        height: "100vh",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CircularProgress
        sx={{ alignSelf: "center" }}
        color="primary"
        size={64}
      />

      <Typography sx={{ mt: 2 }} color="text.secondary" alignSelf="center">
        {tComponent(loadingDesc || LOADING_TEXT)}
      </Typography>
    </Box>
  );
};

import { useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { LoadingIndicator } from "../../processing-pcp";
import {
  useInitAuth,
  useInitPCP,
  useInitInterceptor,
  useAppSelector,
} from "../../../hooks";
import { selectAccount } from "../../auth/store/AuthSelectors";
import { initialUserContext } from "../../../constants";

export const PCP = () => {
  // --------------------- PCP MECHANISM BEGINS WITH THIS HOOK ----------------------------
  const { isPCDProcessing, processedPCD, pcd } = useInitPCP();
  const { msalInstance } = useInitAuth(pcd, processedPCD !== undefined);
  useInitInterceptor(pcd, msalInstance);

  const accountInfo = useAppSelector(selectAccount) ?? initialUserContext;

  useEffect(() => {
    // Once accountInfo is available, we have to set the user info in Sentry
    if (accountInfo.tenantId && accountInfo.username) {
      Sentry.setUser({
        id: accountInfo.tenantId,
        email: accountInfo.username,
      });
    }
  }, [accountInfo]);

  if (isPCDProcessing || !processedPCD || !msalInstance) {
    return <LoadingIndicator />;
  }

  return (
    <MsalProvider instance={msalInstance}>
      {processedPCD.inflatedClient}
    </MsalProvider>
  );
};
